import React from 'react';
import {useState} from 'react';
import {ChevronDown, ChevronUp} from '../../assets/icons';
import styles from '../../styles/Dropdown.module.scss';

const DownloadDropdown = ({products, cols}) => {
  const [downloadType, setDownloadType] = useState('Download');
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDownLoading = () => {
    const timer = setTimeout(() => {
      setDownloadType('Downloading...');
    });

    setTimeout(() => {
      clearTimeout(timer);
      setDownloadType('Download');
    }, 2000);
  };

  const exportColumns = cols.map(col => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, products);
        doc.save('products.pdf');
      });
    });
  };

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(products);
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      saveAsExcelFile(excelBuffer, 'products');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
        );
      }
    });
  };

  return (
    <div>
      <div className={styles.dropdown}>
        {/* <span>Downloading...</span> */}
        <button
          className={
            downloadType === 'Downloading...'
              ? styles.downloadDropdown__toggle
              : styles.dropdown__toggle
          }
          onClick={toggleDropdown}>
          <span
            className={
              downloadType === 'Downloading...'
                ? styles.dropdownTextColor
                : styles.dropdownText
            }>
            {downloadType}
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </span>
        </button>
        {isOpen && (
          <ul className={styles.downloadDropdown__menu}>
            <li
              onClick={() => {
                exportPdf();
                toggleDropdown();
                handleDownLoading();
              }}
              className={styles.downloadDropdown__item}>
              PDF
            </li>

            <li
              onClick={() => {
                exportExcel();
                toggleDropdown();
                handleDownLoading();
              }}
              className={styles.downloadDropdown__item}>
              Excel
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default DownloadDropdown;
