import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import CampaignBreakdown from './pages/CampaignBreakdown/CampaignBreakdown';
import Dashboard from './pages/Dashboard/Dashboard';

import Login from './pages/Login/Login';
import TopCampaigns from './pages/TopCampaigns/TopCampaigns';
import TopCustomer from './pages/TopCustomer/TopCustomer';
import TopTransactions from './pages/TopTransactions/TopTransactions';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/" element={<Dashboard />} />
          <Route path="/reports/top-customer" element={<TopCustomer />} />
          <Route
            path="/reports/top-transactions"
            element={<TopTransactions />}
          />
          <Route path="/reports/top-campaigns" element={<TopCampaigns />} />
          <Route
            path="/reports/campaign-breakdown"
            element={<CampaignBreakdown />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
