import {useEffect} from 'react';

function useLockScrolling(isModalOpen) {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isModalOpen) {
      // Get the current scroll position
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Get the width of the scrollbar
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      // Set the body to position fixed and add styles to hide the scrollbar and adjust the width to account for the scrollbar
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
      document.body.style.overflow = 'hidden';
    } else {
      // Get the current scroll position from the top of the page
      const scrollPosition = parseFloat(document.body.style.top);
      // Remove the styles that were applied to the body element
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';
      // Scroll to the previous scroll position
      window.scrollTo(0, -scrollPosition);
    }
    // return () => {
    //   document.body.style.overflow = 'overflow';
    // };
  }, [isModalOpen]);
}

export default useLockScrolling;
